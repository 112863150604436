.pc-channel {
  width: 100%;
}

.pc-channel .banner {
  width: 100%;
  height: 650px;
  background: url("../../assets/image/channel/banner.png") no-repeat;
  background-size: cover;
}

.pc-channel .banner .banners {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .banner .banners h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  padding-top: 216px;
  margin-bottom: 20px;
}

.pc-channel .banner .banners p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 19px;
  margin-bottom: 30px;
}

.pc-channel .banner .banners .btnBox {
  display: flex;
}

.pc-channel .banner .banners .btnBox a:nth-child(1) {
  width: 154px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #8700EE;
  line-height: 48px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.pc-channel .banner .banners .btnBox a:nth-child(2) {
  width: 154px;
  height: 48px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #FFFFFF;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 48px;
  display: block;
  text-align: center;
  margin-left: 30px;
  cursor: pointer;
}

.pc-channel .mechanism {
  width: 100%;
  height: 442px;
  background: url("../../assets/image/channel/bg1.png") no-repeat;
  background-size: cover;
}

.pc-channel .mechanism .mechanisms {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .mechanism .mechanisms .customer {
  width: 100%;
  height: 114px;
  position: relative;
  top: -67px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
}

.pc-channel .mechanism .mechanisms .customer li {
  width: 285px;
  height: 114px;
  background: linear-gradient(133deg, #F5F5F7 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 32px 0px rgba(5, 15, 104, 0.06);
  border-radius: 6px 6px 6px 6px;
  border: 3px solid #FFFFFF;
  display: flex;
}

.pc-channel .mechanism .mechanisms .customer li > div h4 {
  font-size: 30px;
  font-family: DINPro-Black, DINPro;
  font-weight: 900;
  background: linear-gradient(95.58deg, #EF74A2 0%, #9022F2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 6px;
  position: relative;
  top: -20px;
  left: 32px;
}

.pc-channel .mechanism .mechanisms .customer li > div h4 span {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212 !important;
  line-height: 19px;
}

.pc-channel .mechanism .mechanisms .customer li > div p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 19px;
  margin-top: -15px;
  margin-left: 32px;
}

.pc-channel .mechanism .mechanisms .customer li img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-left: 40px;
}

.pc-channel .mechanism .mechanisms .Hand {
  width: 100%;
  height: calc(100% - 114px);
}

.pc-channel .mechanism .mechanisms .Hand h2 {
  font-size: 36px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 42px;
  text-align: center;
  padding-top: 57px;
  margin-bottom: 60px;
}

.pc-channel .mechanism .mechanisms .Hand > div {
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: space-between;
}

.pc-channel .mechanism .mechanisms .Hand > div li {
  width: 285px;
  height: 112px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
}

.pc-channel .mechanism .mechanisms .Hand > div li img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-top: 32px;
  margin-left: 32px;
}

.pc-channel .mechanism .mechanisms .Hand > div li span {
  font-size: 22px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  display: inline-block;
  margin-left: 16px;
  position: relative;
  top: -15px;
}

.pc-channel .cooperation {
  width: 100%;
  height: 703px;
  background: url("../../assets/image/channel/bg2.png") no-repeat;
  background-size: cover;
}

.pc-channel .cooperation .cooperations {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .cooperation .cooperations h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 20px;
}

.pc-channel .cooperation .cooperations .ps {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 19px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-channel .cooperation .cooperations .brand {
  width: 100%;
  height: 354px;
  display: flex;
  justify-content: space-between;
}

.pc-channel .cooperation .cooperations .brand li {
  width: 278px;
  height: 354px;
  background: #FFFFFF;
  box-shadow: 0px 4px 34px 0px rgba(0, 3, 73, 0.06);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 3px solid #FFFFFF;
}

.pc-channel .cooperation .cooperations .brand li img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.pc-channel .cooperation .cooperations .brand li h4 {
  font-size: 24px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 28px;
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 32px;
}

.pc-channel .cooperation .cooperations .brand li .p {
  width: 100%;
  height: 27px;
  margin-top: 20px;
}

.pc-channel .cooperation .cooperations .brand li .p img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  position: relative;
  top: -20px;
}

.pc-channel .cooperation .cooperations .brand li .p span {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 24px;
  position: relative;
  top: -45px;
  left: 5px;
}

.pc-channel .advantage {
  width: 100%;
  height: 811px;
  background: url("../../assets/image/channel/bg3.png") no-repeat;
  background-size: cover;
}

.pc-channel .advantage .advantages {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .advantage .advantages h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 23px;
}

.pc-channel .advantage .advantages .advantage-p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 23px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-channel .advantage .advantages .rich {
  width: 100%;
  height: 222px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.pc-channel .advantage .advantages .rich li {
  width: 387px;
  height: 222px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.pc-channel .advantage .advantages .rich li .imgs {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-top: 20px;
  margin-left: 24px;
}

.pc-channel .advantage .advantages .rich li span {
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 23px;
  margin-left: 16px;
  position: relative;
  top: -16px;
}

.pc-channel .advantage .advantages .rich li .rich-p {
  width: 100%;
  height: 26px;
  margin-top: 16px;
}

.pc-channel .advantage .advantages .rich li .rich-p .dui {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-left: 24px;
  position: relative;
  top: -13px;
}

.pc-channel .advantage .advantages .rich li .rich-p span {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 26px;
  margin-left: 13px;
  display: inline-block;
  margin-top: 10px;
}

.pc-channel .advantage .advantages .richs {
  width: 793px;
  height: 222px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.pc-channel .advantage .advantages .richs li {
  width: 387px;
  height: 222px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.pc-channel .advantage .advantages .richs li .imgs {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-top: 20px;
  margin-left: 24px;
}

.pc-channel .advantage .advantages .richs li span {
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 23px;
  margin-left: 16px;
  position: relative;
  top: -16px;
}

.pc-channel .advantage .advantages .richs li .rich-p {
  width: 100%;
  height: 26px;
  margin-top: 16px;
}

.pc-channel .advantage .advantages .richs li .rich-p .dui {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-left: 24px;
  position: relative;
  top: -13px;
}

.pc-channel .advantage .advantages .richs li .rich-p span {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 26px;
  margin-left: 13px;
  display: inline-block;
  margin-top: 10px;
}

.pc-channel .strategy {
  width: 100%;
  height: 486px;
}

.pc-channel .strategy .strategys {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .strategy .strategys h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 90px;
  margin-bottom: 30px;
  text-align: center;
}

.pc-channel .strategy .strategys .strategy-p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 19px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-channel .strategy .strategys .certification {
  width: 100%;
  height: 195px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
}

.pc-channel .strategy .strategys .certification li {
  width: 285px;
  height: 195px;
}

.pc-channel .strategy .strategys .certification li img {
  width: 104px;
  height: 104px;
  object-fit: cover;
  margin-left: 91px;
  margin-bottom: 30px;
}

.pc-channel .strategy .strategys .certification li p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 23px;
  text-align: center;
  margin-top: -20px;
}

.pc-channel .process {
  width: 100%;
  height: 635px;
  background: #FBFBFF;
}

.pc-channel .process .processs {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-channel .process .processs h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-channel .process .processs .iconBox {
  width: 1206px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.pc-channel .process .processs .iconBox li img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.pc-channel .process .processs .lang {
  width: 1200px;
  height: 32px;
  object-fit: cover;
  margin-bottom: 20px;
}

.pc-channel .process .processs .applyBox {
  width: 1206px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;
}

.pc-channel .process .processs .applyBox p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 23px;
}

.pc-channel .process .processs a {
  width: 160px;
  height: 48px;
  background: linear-gradient(119deg, #FF9641 0%, #FF56C1 60%, #AE25FF 100%);
  border-radius: 61px 61px 61px 61px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.pc-channel .process .processs a img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: relative;
  top: 5px;
}

.pc-channel .share {
  width: 100%;
  height: 400px;
  background: url("../../assets/image/channel/bg4.png") no-repeat;
  background-size: cover;
}

.pc-channel .share h2 {
  font-size: 36px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42px;
  padding-top: 131px;
  text-align: center;
  margin-bottom: 40px;
}

.pc-channel .share a {
  width: 160px;
  height: 48px;
  border-radius: 49px 49px 49px 49px;
  opacity: 1;
  border: 1px solid #FFFFFF;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.pc-channel .share a img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: relative;
  top: 5px;
}
