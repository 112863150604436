.pc-about {
  width: 100%;
}

.pc-about .banner {
  width: 100%;
  height: 740px;
  background: url("../../assets/image/about/banner.png") no-repeat;
  background-size: cover;
}

.pc-about .banner .banners {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-about .banner .banners h2 {
  font-size: 50px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 76px;
  background: linear-gradient(127deg, #FFB46F 0%, #FC8D8D 8%, #EF37FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 190px;
}

.pc-about .banner .banners h5 {
  font-size: 24px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: bold;
  color: #1A2233;
  line-height: 36px;
  margin-bottom: 32px;
  margin-top: 16px;
}

.pc-about .banner .banners p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 26, 51, 0.8);
  line-height: 30px;
  margin-top: 20px;
}

.pc-about .culture {
  width: 100%;
  height: 866px;
}

.pc-about .culture .cultures {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-about .culture .cultures h2 {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1A2233;
  line-height: 60px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 10px;
}

.pc-about .culture .cultures P {
  font-size: 19px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #676B73;
  line-height: 30px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-about .culture .cultures .valueBox {
  width: 100%;
  height: 526px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.pc-about .culture .cultures .valueBox li {
  width: 580px;
  height: 233px;
  background: #FFFFFF;
  box-shadow: 0px 30px 60px -20px rgba(75, 108, 175, 0.15);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #E6E7EB;
  cursor: pointer;
  display: flex;
}

.pc-about .culture .cultures .valueBox li img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin-top: 40px;
  margin-left: 54px;
}

.pc-about .culture .cultures .valueBox li > div {
  height: 100%;
  margin-left: 40px;
}

.pc-about .culture .cultures .valueBox li > div h3 {
  font-size: 30px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1A2233;
  line-height: 45px;
  margin-top: 40px;
  margin-bottom: 1px;
}

.pc-about .culture .cultures .valueBox li > div h6 {
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #676B73;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.pc-about .culture .cultures .valueBox li > div span {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A2233;
  line-height: 30px;
}

.pc-about .criterion {
  width: 100%;
  height: 710px;
}

.pc-about .criterion .criterions {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-about .criterion .criterions h2 {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1A2233;
  line-height: 60px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 90px;
}

.pc-about .criterion .criterions .criterionBox {
  width: 1205px;
  height: 346px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.pc-about .criterion .criterions .criterionBox li {
  width: 400px;
  height: 120px;
  border-right: 1px solid #D9D9DB;
}

.pc-about .criterion .criterions .criterionBox li img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.pc-about .criterion .criterions .criterionBox li p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A2233;
  line-height: 34px;
  text-align: center;
}

.pc-about .declaration {
  width: 100%;
  height: 538px;
}

.pc-about .declaration .declarations {
  width: 1200px;
  height: 357px;
  background-color: #FFB46F;
  margin-top: 58px;
  margin: 50px auto;
  background: url("../../assets/image/about/bg1.png") no-repeat;
  background-size: cover;
  position: relative;
}

.pc-about .declaration .declarations .LeftBox {
  width: 350px;
  height: 298px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px 10px 10px 10px;
  border: 3px solid #FFFFFF;
  position: absolute;
  top: 30px;
  left: 30px;
}

.pc-about .declaration .declarations .LeftBox h3 {
  font-size: 24px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1A2233;
  line-height: 36px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.pc-about .declaration .declarations .LeftBox p {
  font-size: 14px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(26, 34, 51, 0.8);
  line-height: 26px;
  margin-left: 30px;
}

.pc-about .declaration .declarations .LeftBox img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
}

.pc-about .declaration .declarations .RightBox {
  width: 760px;
  height: 298px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 3px solid #FFFFFF;
  position: absolute;
  left: 410px;
  top: 30px;
}

.pc-about .declaration .declarations .RightBox h3 {
  font-size: 24px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #F13FEB;
  line-height: 36px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.pc-about .declaration .declarations .RightBox h3 span {
  color: #1A2233;
}

.pc-about .declaration .declarations .RightBox p {
  margin-left: 30px;
  font-size: 14px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(26, 34, 51, 0.8);
  line-height: 26px;
}

.pc-about .declaration .declarations .RightBox img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
  right: 0;
  bottom: 0;
}
