.footer {
    width: 100%;
    height: 546px;
    background: #010404;
    margin-top: -30px;
    // position: fixed;
    // bottom: 0;

    .footers {
        width: 1200px;
        height: 100%;
        margin: 0 auto;

        .guarantee {
            width: 100%;
            height: 100px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            ul {
                width: 1150px;
                // height: 30px;
                // background-color: red;
                display: flex;
                margin-bottom: 40px;
                margin-top: 30px;
                justify-content: space-between;

                li {
                    // width: 188px;
                    height: 30px;
                    
                    margin-top: 35px;
                    margin-left: -42px;
                    // line-height: 100px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                    }

                    span {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 22px;
                        position: relative;
                        top: -7px;
                    }
                }
            }
        }

        .business {
            width: 1200px;
            height: 364px;
            display: flex;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .businessLeft {
                width: 450px;
                height: 100%;

                .logo {
                    width: 126px;
                    height: 27px;
                    object-fit: cover;
                    margin-top: 50px;
                    margin-bottom: 19px;
                }

                .span {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(255,255,255,0.85);
                    line-height: 18px;
                    position: relative;
                    top: -26px;
                }
                li{
                    margin-top: 20px;
                    span{
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(255,255,255,0.85);
                        line-height: 18px;
                        margin-left: 8px;
                    }
                    img{
                        width: 16px;
                        height: 16px;
                        object-fit: cover;
                        position: relative;
                        top: 3px;
                    }
                }
                // li {
                //     font-size: 14px;
                //     font-family: PingFang-SC-Medium, PingFang-SC;
                //     font-weight: 500;
                //     color: rgba(255, 255, 255, 0.85);
                //     line-height: 18px;
                //     margin-top: 14px;

                //     // margin-bottom: 17px;
                //     span {
                //         font-size: 12px;
                //         font-family: PingFangSC-Regular, PingFang SC;
                //         font-weight: 400;
                //         color: rgba(255, 255, 255, 0.65);
                //         line-height: 18px;
                //     }
                // }

                
                .p{
                    width: 165px;
                    display: flex;
                    margin-left: 15px;
                    // background-color: aquamarine;
                    justify-content: space-between;
                    p {
                        font-size: 12px;
                        font-family: PingFang-SC-Medium, PingFang-SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 22px;
                        margin-top: 3px;
                    }
                }
                


                // span{
                //     width: 76px;
                //     text-align: center;
                // }

            }

            .businessRight {
                width: 750px;
                height: 272px;
                margin-top: 50px;
                // border-left: 1px solid rgba(255, 255, 255, 0.1);
                
                ul{
                    width: 705px;
                    height: 100%;
                    display: flex;
                    // margin-left: 55px;
                    justify-content: space-between;
                    margin-top: -30px;
                    li{
                        width: 70px;
                        height: 100%;
                        // margin-left: 76px;
                        p{
                            font-size: 12px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-bottom: 24px;
                            text-align: center;
                            // line-height: 22px;  
                        }
                        .disBox {
                            margin-top: 39px;
                            // width: 192px;
                            // display: flex;
                            // justify-content: space-between;
                            .code {
                                width: 76px;
                                height: 76px;
                                object-fit: cover;
                                // margin-top: 39px;
                                cursor: pointer;
                            }
                            
                        }
                        
                        h6{
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 22px;
                            margin-bottom: 20px;
                        }
                        a{
                            text-decoration: none;
                            font-size: 12px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #CCCCCC;
                            line-height: 18px;
                            display: block;
                            margin-bottom: 16px;
                            // font-size: 12px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                            // font-weight: 400;
                            // color: #9DA5B7;
                            // line-height: 18px;
                            // display: block;
                            // margin-bottom: 16px;
    
                        }
                    }
                }
                
                // li:nth-child(1){
                //     margin-left: 63px;
                // }
            }
        }
        .list{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: space-between;
            li{
                // font-size: 12px;
                // font-family: PingFangSC-Regular, PingFang SC;
                // font-weight: 400;
                // color: rgba(255,255,255,0.25);
                // line-height: 80px;
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                line-height: 20px;
                margin-top: 31px;
                cursor: pointer;
                a{
                    margin-left: 32px;
                    margin-bottom: 1px solid #ccc;
                    color: #CCCCCC;
                }
                .link-wen {
                    &:hover {
                        color: #0758f1;
                    }
                }
            }
        }
    }
}