* {
    list-style: none;
}

.nav {
    width: 100%;
    height: 70px;
    min-width: 1350px;
    position: fixed;
    top: 0;
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    transition: background-color 0.2s;
    display: flex;
    // background-color: #6F24FF;

    .navCon {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: pink;
    }

    .navLog {
        width: 126px;
        height: 30px;
        background: url('../../assets/image/header/logo.png') no-repeat;
        background-size: cover;
    }

    .navBlackLog {
        width: 126px;
        height: 30px;
        background: url('../../assets/image/home/mobileLogos.png') no-repeat;
        background-size: cover;
    }

    .navCenter {
        width: 640px;
        height: 70px;
        margin-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            width: 64px;
            height: 100%;
            line-height: 70px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }

        a:hover {
            color: #6F24FF;
        }
    }

    .navRight {
        height: 70px;
        margin-left: 67px;
        display: flex;

        .rightPhone {
            width: 24px;
            height: 24px;
            background: url('../../assets/image/header/phone.png') no-repeat;
            background-size: cover;
            margin-top: 23px;
        }

        .blackPhone {
            width: 24px;
            height: 24px;
            background: url('../../assets/image/header/blackPhone.png') no-repeat;
            background-size: cover;
        }

        span {
            font-size: 20px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            // color: #FFFFFF;
            line-height: 70px;
            // display: block;
            margin-left: 8px;
            // margin-top: -22px;
        }

        .a {
            text-decoration: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            line-height: 70px;
            margin-left: 30px;
        }

        button {
            width: 90px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6F24FF;
            // line-height: 70px;
            border: none;
            margin-left: 20px;
            margin-top: 17px;
            cursor: pointer;
        }
    }
}

.nav:hover {
    background-color: hsla(0, 0%, 100%, .9) !important;

    .navLog {
        width: 126px;
        height: 30px;
        background: url('../../assets/image/home/mobileLogos.png') no-repeat;
        background-size: cover;
    }

    a {
        color: #000000 !important;
    }

    .a {
        color: #6F24FF !important;
    }

    span {
        color: #000000;
    }

    .rightPhone {
        width: 24px;
        height: 24px;
        background: url('../../assets/image/header/blackPhone.png') no-repeat;
        background-size: cover;
    }

    button {
        width: 90px;
        height: 36px;
        background: #6F24FF !important;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff !important;
        border: none;
    }
}