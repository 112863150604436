.pc-solution {
  width: 100%;
}

.pc-solution .banner {
  width: 100%;
  height: 640px;
  background: url("../../assets/image/solution/banner.png") no-repeat;
  background-size: cover;
}

.pc-solution .banner .banners {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .banner .banners h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9B2AEA;
  line-height: 56px;
  padding-top: 237px;
  margin-bottom: 20px;
}

.pc-solution .banner .banners h2 span {
  color: #000;
}

.pc-solution .banner .banners p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(18, 18, 18, 0.8);
  line-height: 23px;
  margin-bottom: 40px;
}

.pc-solution .banner .banners a {
  width: 168px;
  height: 52px;
  background: #9B2AEA;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 52px;
  display: block;
  text-align: center;
}

.pc-solution .setUp {
  width: 100%;
  height: 611px;
}

.pc-solution .setUp .setUps {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .setUp .setUps h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 60px;
  text-align: center;
  margin-bottom: 40px;
}

.pc-solution .setUp .setUps img {
  width: 100%;
  height: 384px;
  object-fit: cover;
}

.pc-solution .provide {
  width: 100%;
  height: 723px;
  background: #FCF4FF;
}

.pc-solution .provide .provides {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .provide .provides h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 90px;
  text-align: center;
  margin-bottom: 40px;
}

.pc-solution .provide .provides .provides-list {
  width: 100%;
  height: 436px;
  display: flex;
  justify-content: space-between;
}

.pc-solution .provide .provides .provides-list li:hover {
  transform: translateY(-10px);
  transition: transform 0.3s linear;
}

.pc-solution .provide .provides .provides-list li {
  width: 360px;
  height: 436px;
  background-color: #fff;
  margin-left: -39px;
  box-shadow: 0px 4px 44px 0px rgba(51, 6, 67, 0.03);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #FFFFFF;
  cursor: pointer;
}

.pc-solution .provide .provides .provides-list li .img {
  width: 100%;
  height: 256px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.pc-solution .provide .provides .provides-list li .equipment {
  width: 100%;
  height: 180px;
  margin-top: -4px;
}

.pc-solution .provide .provides .provides-list li .equipment img {
  width: 98px;
  height: 98px;
  object-fit: cover;
  margin-left: 131px;
  display: inline-block;
}

.pc-solution .provide .provides .provides-list li .equipment p {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  text-align: center;
  margin-top: -32px;
}

.pc-solution .demand {
  width: 100%;
  height: 758px;
  background: #FAFAFA;
  margin-top: -18px;
}

.pc-solution .demand .demands {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .demand .demands h2 {
  font-size: 48px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 56px;
  padding-top: 86px;
  margin-bottom: 30px;
  text-align: center;
}

.pc-solution .demand .demands .hoverBox {
  width: 100%;
  height: 490px;
}

.pc-solution .demand .demands .hoverBox .hoverTop {
  width: 508px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.pc-solution .demand .demands .hoverBox .hoverTop li {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 45px;
  cursor: pointer;
}

.pc-solution .demand .demands .hoverBox .hoverTop li.cur {
  width: 120px;
  height: 44px;
  background: linear-gradient(139deg, #AC24FF 0%, #6F24FF 100%);
  border-radius: 40px 40px 40px 40px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 44px;
  text-align: center;
}

.pc-solution .demand .demands .hoverBox .hoverBom {
  width: 100%;
  height: 405px;
  box-shadow: 0px 7px 84px 0px rgba(2, 15, 31, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont {
  display: flex;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .imgs {
  width: 526px;
  height: 406px;
  object-fit: cover;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox {
  width: 674px;
  height: 100%;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox h3 {
  font-size: 28px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 33px;
  margin-bottom: 10px;
  margin-left: 40px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(18, 18, 18, 0.7);
  line-height: 26px;
  margin-left: 40px;
  margin-bottom: 64px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox h6 {
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 19px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox h6:before {
  content: '';
  width: 30px;
  height: 4px;
  background: #6F24FF;
  border-radius: 13px 13px 13px 13px;
  display: block;
  position: relative;
  top: -33px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox .disBox {
  width: 382px;
  height: 158px;
  display: flex;
  margin-left: 40px;
  justify-content: space-between;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox .disBox li {
  width: 114px;
  height: 158px;
  background: rgba(226, 231, 239, 0.2);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid rgba(221, 221, 221, 0.32);
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox .disBox li img {
  width: 86px;
  height: 86px;
  object-fit: cover;
  margin-top: 21px;
  margin-left: 14px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .imgFont .FontBox .disBox li p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 16px;
  text-align: center;
  margin-left: 4px;
}

.pc-solution .demand .demands .hoverBox .hoverBom .tab_con_li {
  display: none;
}

.pc-solution .demand .demands .hoverBox .hoverBom .tab_con_li.current {
  display: block;
}

.pc-solution .process {
  width: 100%;
  height: 552px;
}

.pc-solution .process .processs {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .process .processs h2 {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #121212;
  line-height: 47px;
  padding-top: 120px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-solution .process .processs .imgBox {
  width: 1112px;
  height: 88px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pc-solution .process .processs .imgBox li {
  width: 88px;
  height: 88px;
}

.pc-solution .process .processs .imgBox li img {
  width: 88px;
  height: 88px;
  object-fit: cover;
}

.pc-solution .process .processs .lang {
  width: 1200px;
  height: 44px;
  object-fit: cover;
}

.pc-solution .process .processs .fontBox {
  width: 1150px;
  margin: 0 auto;
  display: flex;
}

.pc-solution .process .processs .fontBox p {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #121212;
  line-height: 23px;
}

.pc-solution .process .processs .fontBox p:nth-child(2) {
  margin-left: 51px;
}

.pc-solution .process .processs .fontBox p:nth-child(3) {
  margin-left: 72px;
}

.pc-solution .process .processs .fontBox p:nth-child(4) {
  margin-left: 91px;
}

.pc-solution .process .processs .fontBox p:nth-child(5) {
  margin-left: 93px;
}

.pc-solution .process .processs .fontBox p:nth-child(6) {
  margin-left: 73px;
}

.pc-solution .process .processs .fontBox p:nth-child(7) {
  margin-left: 67px;
}

.pc-solution .enjoy {
  width: 100%;
  height: 668px;
  background: url("../../assets/image/solution/bg1.png") no-repeat;
  background-size: cover;
}

.pc-solution .enjoy .enjoys {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-solution .enjoy .enjoys h2 {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 47px;
  padding-top: 120px;
  text-align: center;
  margin-bottom: 40px;
}

.pc-solution .enjoy .enjoys .course {
  width: 1155px;
  height: 312px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pc-solution .enjoy .enjoys .course li:hover {
  transform: translateY(-10px);
  transition: transform 0.3s linear;
}

.pc-solution .enjoy .enjoys .course li {
  width: 588px;
  height: 146px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-top: 20px;
  margin-left: -40px;
  display: flex;
  cursor: pointer;
}

.pc-solution .enjoy .enjoys .course li img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-top: 33px;
  margin-left: 40px;
}

.pc-solution .enjoy .enjoys .course li > div {
  margin-left: 20px;
}

.pc-solution .enjoy .enjoys .course li > div h6 {
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #191919;
  margin-top: 30px;
  margin-bottom: 8px;
}

.pc-solution .enjoy .enjoys .course li > div p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #808794;
  line-height: 24px;
}
