.pc-Private {
  width: 100%;
}

.pc-Private .banner {
  width: 100%;
  height: 740px;
  background: url("../../assets/image/private/banner.png") no-repeat;
  background-size: cover;
}

.pc-Private .banner .banners {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-Private .banner .banners h2 {
  font-size: 60px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 94px;
  padding-top: 189px;
  margin-bottom: 19px;
}

.pc-Private .banner .banners p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 48px;
  margin-bottom: 80px;
}

.pc-Private .banner .banners a {
  width: 180px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
  display: block;
}

.pc-Private .solve {
  width: 100%;
  height: 674px;
}

.pc-Private .solve .solves {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-Private .solve .solves h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 56px;
  padding-top: 80px;
  margin-bottom: 16px;
  text-align: center;
}

.pc-Private .solve .solves p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-Private .solve .solves .hoverBox {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: space-between;
}

.pc-Private .solve .solves .hoverBox li {
  width: 184px;
  height: 240px;
  background: url("../../assets/image/private/bg2.png") no-repeat;
  background-size: cover;
  margin-left: -10px;
  cursor: pointer;
}

.pc-Private .solve .solves .hoverBox li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-top: 52px;
  margin-left: 61px;
  margin-bottom: 10px;
}

.pc-Private .solve .solves .hoverBox li span {
  width: 184px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  display: block;
  text-align: center;
  margin-left: 6px;
}

.pc-Private .solve .solves .langs {
  width: 1210px;
  height: 20px;
  object-fit: cover;
  margin-bottom: 23px;
}

.pc-Private .solve .solves .col-Box {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-around;
}

.pc-Private .solve .solves .col-Box h3 {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #191919;
  margin-top: 0px;
}

.pc-Private .solve .solves .col-Box h3:nth-child(1) {
  position: relative;
  left: -5px;
}

.pc-Private .solve .solves .col-Box h3:nth-child(4) {
  position: relative;
  left: 8px;
}

.pc-Private .solve .solves .col-Box h3:nth-child(5) {
  position: relative;
  left: 13px;
}

.pc-Private .solve .solves .col-Box h3:nth-child(6) {
  position: relative;
  left: 18px;
}

.pc-Private .LiveRoom {
  width: 100%;
  height: 562px;
  background: #FCFBFF;
}

.pc-Private .LiveRoom .LiveRooms {
  width: 1200px;
  height: 430px;
  margin: 0 auto;
  display: flex;
}

.pc-Private .LiveRoom .LiveRooms .Leftbox {
  width: 600px;
  height: 100%;
  margin-top: 66px;
}

.pc-Private .LiveRoom .LiveRooms .Leftbox img {
  width: 600px;
  height: 430px;
  object-fit: cover;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox {
  width: 600px;
  height: 100%;
  margin-top: 97px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #191919;
  line-height: 56px;
  margin-left: 65px;
  margin-bottom: 20px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox p {
  display: flex;
  margin-bottom: 16px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox p img {
  width: 26px;
  height: 26px;
  object-fit: cover;
  margin-left: 66px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox p span {
  width: 500px;
  height: 52px;
  display: block;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  line-height: 26px;
  margin-left: 8px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox .detial {
  width: 154px;
  height: 50px;
  background: linear-gradient(315deg, #5F1AFF 0%, #8733FF 100%);
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  display: block;
  margin-left: 65px;
  float: left;
  margin-top: 64px;
}

.pc-Private .LiveRoom .LiveRooms .Rightbox .experience {
  width: 154px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #6F24FF;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F24FF;
  line-height: 50px;
  display: block;
  text-align: center;
  margin-left: 235px;
  margin-top: 79px;
}

.pc-Private .efficiency {
  width: 100%;
  height: 562px;
}

.pc-Private .efficiency .efficiencys {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.pc-Private .efficiency .efficiencys .LeftBoxs {
  width: 600px;
  height: 430px;
  margin-top: 102px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #191919;
  line-height: 56px;
  margin-left: 65px;
  margin-bottom: 20px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs p {
  display: flex;
  margin-bottom: 16px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs p img {
  width: 26px;
  height: 26px;
  object-fit: cover;
  margin-left: 66px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs p span {
  width: 500px;
  display: block;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  line-height: 26px;
  margin-left: 8px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs .detial {
  width: 154px;
  height: 50px;
  background: linear-gradient(315deg, #5F1AFF 0%, #8733FF 100%);
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  display: block;
  margin-left: 65px;
  float: left;
  margin-top: 64px;
}

.pc-Private .efficiency .efficiencys .LeftBoxs .experience {
  width: 154px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #6F24FF;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F24FF;
  line-height: 50px;
  display: block;
  text-align: center;
  margin-left: 235px;
  margin-top: 79px;
}

.pc-Private .efficiency .efficiencys .RightBoxs {
  width: 600px;
  height: 430px;
  margin-top: 66px;
}

.pc-Private .efficiency .efficiencys .RightBoxs img {
  width: 600px;
  height: 430px;
  object-fit: cover;
  margin-left: 65px;
}

.pc-Private .promotion {
  width: 100%;
  height: 640px;
  background: url("../../assets/image/private/bg1.png") no-repeat;
  background-size: cover;
}

.pc-Private .promotion .promotions {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-Private .promotion .promotions h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  padding-top: 80px;
  text-align: center;
  margin-bottom: 16px;
}

.pc-Private .promotion .promotions p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-Private .promotion .promotions .business {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: space-between;
}

.pc-Private .promotion .promotions .business li {
  width: 380px;
  height: 320px;
  background: #FFFFFF;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.pc-Private .promotion .promotions .business li img {
  width: 66px;
  height: 66px;
  object-fit: cover;
  margin-top: 47px;
  margin-left: 39px;
}

.pc-Private .promotion .promotions .business li h3 {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 33px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.pc-Private .promotion .promotions .business li p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-left: 40px;
  text-align: left;
}

.pc-Private .pole {
  width: 100%;
  height: 778px;
}

.pc-Private .pole .poles {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-Private .pole .poles h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 70px;
  margin-bottom: 16px;
}

.pc-Private .pole .poles p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 45px;
}

.pc-Private .pole .poles img {
  width: 1238px;
  height: 492px;
  object-fit: cover;
}
