@charset "UTF-8";
a {
  text-decoration: none;
}

.pc-continer {
  width: 100%;
}

.pc-continer .swiperBox {
  width: 100%;
  height: 700px;
  position: relative;
}

.pc-continer .swiperBox .side {
  width: 100%;
  height: 100%;
}

.pc-continer .swiperBox .side img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  cursor: pointer;
}

.pc-continer .swiperBox .side .side-con {
  width: 1200px;
  height: 700px;
  background-color: pink;
  position: absolute;
  left: 50%;
  margin-left: -600px;
}

.pc-continer .interface-new {
  width: 100%;
  height: 888px;
  margin-bottom: 32px;
  background: url("../../assets/image/home/bg0.png") no-repeat;
  background-size: cover;
}

.pc-continer .interface-new .interface-news {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .interface-new .interface-news .hov-box {
  width: 100%;
  height: 140px;
  margin-bottom: 40px;
  position: relative;
  top: -36px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
}

.pc-continer .interface-new .interface-news .hov-box li:hover {
  transform: translateY(-9px);
  transition: transform 0.3s linear;
}

.pc-continer .interface-new .interface-news .hov-box li {
  width: 285px;
  height: 140px;
  background: url(../../assets/image/home/bg1.png) no-repeat;
  background-size: cover;
  cursor: pointer;
}

.pc-continer .interface-new .interface-news .hov-box li h6 {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  margin-left: 20px;
}

.pc-continer .interface-new .interface-news .hov-box li p {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pc-continer .interface-new .interface-news .hov-box li span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F24FF;
  line-height: 20px;
  float: right;
  position: relative;
  left: -25px;
}

.pc-continer .interface-new .interface-news .hov-box li img {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-left: 7px;
  position: relative;
  top: 3px;
}

.pc-continer .interface-new .interface-news .hov-box li.cur {
  background: url("../../assets/image/home/hover-bg1.png") no-repeat;
  background-size: cover;
}

.pc-continer .interface-new .interface-news .hov-box li.cur h6 {
  color: #fff;
}

.pc-continer .interface-new .interface-news .hov-box li.cur p {
  color: #fff;
}

.pc-continer .interface-new .interface-news .hov-box li.cur span {
  color: #fff;
}

.pc-continer .interface-new .interface-news .hov-box li.cur img {
  content: url("../../assets/image/home/hover-jt.png");
}

.pc-continer .interface-new .interface-news .radiation {
  width: 100%;
  height: 84px;
  margin-bottom: 108px;
  display: flex;
  justify-content: space-between;
}

.pc-continer .interface-new .interface-news .radiation li {
  width: 260px;
  height: 100%;
  position: relative;
}

.pc-continer .interface-new .interface-news .radiation li img {
  width: 84px;
  height: 84px;
  object-fit: cover;
}

.pc-continer .interface-new .interface-news .radiation li p {
  font-size: 44px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #191919;
  line-height: 52px;
  position: absolute;
  top: 0px;
  left: 84px;
}

.pc-continer .interface-new .interface-news .radiation li p span {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #191919;
  line-height: 24px;
}

.pc-continer .interface-new .interface-news .radiation li h6 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  top: 20px;
  left: 90px;
}

.pc-continer .interface-new .interface-news .business {
  width: 100%;
  height: 776px;
  position: relative;
}

.pc-continer .interface-new .interface-news .business h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 16px;
}

.pc-continer .interface-new .interface-news .business p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 80px;
}

.pc-continer .interface-new .interface-news .business .hoverBox {
  width: 100%;
  height: 472px;
  display: flex;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft {
  width: 232px;
  height: 100%;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li {
  width: 232px;
  height: 103px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 6px;
  margin-top: -5px;
  cursor: pointer;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li h4 {
  width: 84px;
  height: 56px;
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  letter-spacing: 1px;
  padding-top: 24px;
  margin-left: 33px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  position: relative;
  top: -80px;
  left: 150px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur {
  width: 232px;
  height: 103px;
  background: linear-gradient(315deg, #7628FF 0%, #9466FF 100%);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1), inset 0px 2px 8px 0px #9C9BFF;
  border-radius: 6px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur h4 {
  color: #fff;
  font-weight: bold;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur .img1 {
  content: url("../../assets/image/home/img1.png");
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur .img2 {
  content: url("../../assets/image/home/img2（2）.png");
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur .img3 {
  content: url("../../assets/image/home/img3（2）.png");
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverLeft li.cur .img4 {
  content: url("../../assets/image/home/img4（2）.png");
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight {
  width: 740px;
  height: 472px;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 4px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 6px;
  border: 1px solid #FFFFFF;
  margin-top: 20px;
  margin-left: 20px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight h3 {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #2B1656;
  line-height: 25px;
  padding-top: 20px;
  margin-left: 44px;
  margin-bottom: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin-left: 44px;
  text-align: left;
  margin-bottom: 36px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pp {
  width: 260px;
  height: 33px;
  background: #F9EEFF;
  margin-left: 44px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pp .before {
  width: 12px;
  height: 12px;
  object-fit: cover;
  position: relative;
  top: -5px;
  left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pp span {
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #8E5DEC;
  line-height: 33px;
  letter-spacing: 1px;
  margin-left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pp .back {
  width: 12px;
  height: 12px;
  object-fit: cover;
  margin-left: 16px;
  position: relative;
  top: -5px;
  left: -15px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pps {
  width: 326px;
  height: 33px;
  background: #F9EEFF;
  margin-left: 44px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pps .before {
  width: 12px;
  height: 12px;
  object-fit: cover;
  position: relative;
  top: -5px;
  left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pps span {
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #8E5DEC;
  line-height: 33px;
  letter-spacing: 1px;
  margin-left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .pps .back {
  width: 12px;
  height: 12px;
  object-fit: cover;
  margin-left: 16px;
  position: relative;
  top: -5px;
  left: -15px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .ppss {
  width: 240px;
  height: 33px;
  background: #F9EEFF;
  margin-left: 44px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .ppss .before {
  width: 12px;
  height: 12px;
  object-fit: cover;
  position: relative;
  top: -5px;
  left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .ppss span {
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #8E5DEC;
  line-height: 33px;
  letter-spacing: 1px;
  margin-left: 16px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .ppss .back {
  width: 12px;
  height: 12px;
  object-fit: cover;
  position: relative;
  top: -5px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .fontBox {
  width: 540px;
  height: 122px;
  background: #FDF8FF;
  box-shadow: 0px 2px 8px 0px rgba(102, 30, 255, 0.07);
  margin-left: 44px;
  margin-top: -12px;
  margin-bottom: 47px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .fontBox p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
  line-height: 24px;
  padding-top: 21px;
  margin-left: 35px;
  margin-bottom: 46px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .fontsBox {
  width: 540px;
  height: 122px;
  background: #FDF8FF;
  box-shadow: 0px 2px 8px 0px rgba(102, 30, 255, 0.07);
  margin-left: 44px;
  margin-top: -12px;
  margin-bottom: 58px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .fontsBox p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
  line-height: 24px;
  padding-top: 11px;
  margin-left: 35px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .disBox {
  width: 575px;
  display: flex;
  justify-content: space-between;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .disBox img {
  width: 190px;
  height: 40px;
  object-fit: cover;
  margin-left: 44px;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .disBox a {
  width: 120px;
  height: 46px;
  background: linear-gradient(315deg, #7628FF 0%, #9466FF 100%);
  border-radius: 23px;
  display: block;
  text-decoration: none;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 46px;
  text-align: center;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .tab_con_li {
  display: none;
}

.pc-continer .interface-new .interface-news .business .hoverBox .hoverRight .tab_con_li.current {
  display: block;
}

.pc-continer .interface-new .interface-news .business .img6 {
    width: 266px;
    object-fit: contain;
    position: absolute;
    top: 302px;
    left: 1004px;
}

.pc-continer .consumption {
  width: 100%;
  height: 687px;
  margin-top: -35px;
}

.pc-continer .consumption .consumptions {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .consumption .consumptions h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  padding-top: 70px;
  text-align: center;
  margin-bottom: 16px;
}

.pc-continer .consumption .consumptions p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 61px;
}

.pc-continer .consumption .consumptions .consumption-hovBox {
  width: 100%;
  height: 380px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pc-continer .consumption .consumptions .consumption-hovBox li {
  width: 386px;
  height: 180px;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

.pc-continer .consumption .consumptions .consumption-hovBox li h6 {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-left: 30px;
  margin-bottom: 5px;
}

.pc-continer .consumption .consumptions .consumption-hovBox li p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #93969C;
  line-height: 24px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 23px;
  text-align: left;
  margin-left: 30px;
}

.pc-continer .consumption .consumptions .consumption-hovBox li span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F24FF;
  line-height: 20px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 240px;
  position: relative;
  top: 2px;
}

.pc-continer .consumption .consumptions .consumption-hovBox li img {
  width: 84px;
  height: 52px;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}

.pc-continer .consumption .consumptions .consumption-hovBox li .whjt {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: absolute;
  top: 129px;
  left: 333px;
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur {
  background: linear-gradient(315deg, #7628FF 0%, #9466FF 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #fff !important;
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur h6 {
  color: #fff;
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  line-height: 24px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(1) .one {
  content: url("../../assets/image/home/ones.png");
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(2) .two {
  content: url("../../assets/image/home/twos.png");
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(3) .three {
  content: url("../../assets/image/home/threes.png");
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(4) .fore {
  content: url("../../assets/image/home/fores.png");
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(5) .five {
  content: url("../../assets//image//home/fives.png");
}

.pc-continer .consumption .consumptions .consumption-hovBox li.cur.cur:nth-child(6) .six {
  content: url("../../assets/image/home/sixs.png");
}

.pc-continer .paper {
  width: 100%;
  height: 876px;
  background: url("../../assets/image/home/bg2.png") no-repeat;
  background-size: cover;
}

.pc-continer .paper .papers {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .paper .papers h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  text-align: center;
  padding-top: 90px;
}

.pc-continer .paper .papers p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 103px;
  text-align: center;
}

.pc-continer .paper .papers .Cash {
  width: 100%;
  height: 430px;
  display: flex;
}

.pc-continer .paper .papers .Cash li {
  width: 276px;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.08) 100%);
  box-shadow: 0px 4px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 6px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.1)) 1 1;
  margin-left: 32px;
  position: relative;
  cursor: pointer;
}

.pc-continer .paper .papers .Cash li img {
  width: 140px;
  height: 140px;
  margin: 22px 68px;
}

.pc-continer .paper .papers .Cash li h4 {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 25px;
  margin-left: 38px;
  margin-top: -15px;
  opacity: 90%;
}

.pc-continer .paper .papers .Cash li p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  margin-left: 38px;
  text-align: left;
  margin-top: -10px;
}

.pc-continer .paper .papers .Cash li a {
  width: 128px;
  height: 46px;
  background: linear-gradient(315deg, #7628FF 0%, #9466FF 100%);
  border-radius: 23px;
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 46px;
  text-align: center;
  text-decoration: none;
  position: absolute;
  top: 350px;
  left: 74px;
}

.pc-continer .paper .papers .Cash li.cur {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.08) 100%);
  box-shadow: 0px 4px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 6px;
  border: 1px solid;
  border-image: linear-gradient(315deg, #7628ff, #9466ff) 1 1;
}

.pc-continer .advantage {
  width: 100%;
  height: 678px;
}

.pc-continer .advantage .advantages {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .advantage .advantages h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  padding-top: 70px;
  margin-bottom: 16px;
  text-align: center;
}

.pc-continer .advantage .advantages p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-continer .advantage .advantages .advantageBox {
  width: 100%;
  height: 362px;
  display: flex;
  justify-content: space-between;
}

.pc-continer .advantage .advantages .advantageBox li {
  width: 285px;
  height: 362px;
  background: #FFFFFF;
  box-shadow: 0px 4px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.pc-continer .advantage .advantages .advantageBox li img {
  width: 285px;
  height: 362px;
}

.pc-continer .advantage .advantages .advantageBox li h3 {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(25, 25, 25, 0.85);
  line-height: 25px;
  position: absolute;
  top: 47px;
  left: 23px;
  margin-bottom: 22px;
}

.pc-continer .advantage .advantages .advantageBox li h3 span {
  font-size: 20px;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: rgba(25, 25, 25, 0.85);
  line-height: 24px;
  letter-spacing: 1px;
}

.pc-continer .advantage .advantages .advantageBox li p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 22px;
  position: absolute;
  top: 94px;
  left: 23px;
  text-align: left;
}

.pc-continer .advantage .advantages .advantageBox li.cur.cur:nth-child(1) img {
  width: 285px;
  height: 362px;
  content: url("../../assets/image/home/01_on.png");
}

.pc-continer .advantage .advantages .advantageBox li.cur.cur:nth-child(2) img {
  content: url("../../assets/image/home/02_on.png");
}

.pc-continer .advantage .advantages .advantageBox li.cur.cur:nth-child(3) img {
  content: url("../../assets/image/home/03_on.png");
}

.pc-continer .advantage .advantages .advantageBox li.cur.cur:nth-child(4) img {
  content: url("../../assets/image/home/04_on.png");
}

.pc-continer .support {
  width: 100%;
  height: 800px;
  background: url("../../assets/image/home/bg3.png") no-repeat;
  background-size: cover;
}

.pc-continer .support .supports {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .support .supports h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  padding-top: 70px;
  text-align: center;
  margin-bottom: 16px;
}

.pc-continer .support .supports p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 43px;
  text-align: center;
}

.pc-continer .support .supports .supportBox {
  width: 100%;
  height: 502px;
}

.pc-continer .support .supports .supportBox .supportTop {
  width: 100%;
  height: 44px;
  margin-bottom: 13px;
  border-bottom: 2px solid rgba(111, 36, 255, 0.06);
}

.pc-continer .support .supports .supportBox .supportTop ul {
  width: 724px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.pc-continer .support .supports .supportBox .supportTop ul li {
  width: 160px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  text-align: center;
  cursor: pointer;
}

.pc-continer .support .supports .supportBox .supportTop ul li.cur {
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #6F24FF;
  border-bottom: 4px solid #9466FF;
}

.pc-continer .support .supports .supportBox .supportBom {
  width: 100%;
  height: 404px;
}

.pc-continer .support .supports .supportBox .supportBom li:hover {
  transform: translateY(-9px);
  transition: transform 0.3s linear;
}

.pc-continer .support .supports .supportBox .supportBom li {
  width: 285px;
  height: 192px;
  float: left;
  margin-left: 10px;
  margin-top: 30px;
  position: relative;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 4px 26px 0px rgba(56, 15, 130, 0.08);
  border-radius: 6px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
}

.pc-continer .support .supports .supportBox .supportBom li h4 {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
  margin-left: 20px;
}

.pc-continer .support .supports .supportBox .supportBom li img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
}

.pc-continer .support .supports .supportBox .supportBom li p {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: left;
  margin-left: 20px;
}

.pc-continer .support .supports .supportBox .supportBom li.cur h4::after {
  content: '';
  display: block;
  position: absolute;
  top: 58px;
  transform: translateY(-50%);
  width: 35px;
  height: 4px;
  background: linear-gradient(315deg, #7628FF 0%, #9466FF 100%);
  border-radius: 2px;
}

.pc-continer .support .supports .supportBox .supportBom .tab_con_li {
  display: none;
}

.pc-continer .support .supports .supportBox .supportBom .tab_con_li.current {
  display: block;
}

.pc-continer .mechanism {
  width: 100%;
  height: 778px;
}

.pc-continer .mechanism .mechanisms {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .mechanism .mechanisms h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  padding-top: 70px;
  margin-bottom: 16px;
  text-align: center;
}

.pc-continer .mechanism .mechanisms p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 45px;
}

.pc-continer .mechanism .mechanisms img {
  width: 1238px;
  height: 492px;
  object-fit: cover;
}

.pc-continer .NewsUpdate {
  width: 100%;
  height: 794px;
  background: #F9F9F9;
}

.pc-continer .NewsUpdate .NewsUpdates {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .NewsUpdate .NewsUpdates h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(25, 25, 25, 0.85);
  line-height: 60px;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 70px;
  margin-bottom: 16px;
}

.pc-continer .NewsUpdate .NewsUpdates p {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.65);
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 60px;
  text-align: center;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox {
  width: 100%;
  height: 488px;
  display: flex;
  justify-content: space-between;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft {
  width: 590px;
  height: 100%;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li {
  width: 590px;
  height: 122px;
  background: #FFFFFF;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  margin-top: -23px;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li p {
  font-size: 24px;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  line-height: 29px;
  letter-spacing: 1px;
  text-align: left;
  margin-left: 26px;
  padding-top: 25px;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li p span {
  font-size: 14px;
  font-family: DIN-Regular, DIN;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.25);
  line-height: 17px;
  letter-spacing: 1px;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li h5 {
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(25, 25, 25, 0.85);
  line-height: 22px;
  position: absolute;
  top: -3px;
  left: 120px;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li .p {
  width: 456px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.55);
  line-height: 20px;
  position: absolute;
  top: 23px;
  left: 93px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsLeft li.cur {
  background: linear-gradient(360deg, #FFFFFF 0%, #F8F7FF 100%);
  box-shadow: 0px 4px 10px 0px rgba(56, 15, 130, 0.05);
  border-radius: 8px;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsRight {
  width: 590px;
  height: 100%;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsRight img {
  width: 590px;
  height: 488px;
  object-fit: cover;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsRight .tab_con_li {
  display: none;
}

.pc-continer .NewsUpdate .NewsUpdates .NewsBox .NewsRight .tab_con_li.current {
  display: block;
}

.pc-continer .soldier {
  width: 100%;
  height: 510px;
  background: url("../../assets/image/home/bg5.png") no-repeat;
  background-size: cover;
}

.pc-continer .soldier .soldiers {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.pc-continer .soldier .soldiers h2 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 60px;
  letter-spacing: 1px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding-top: 70px;
  text-align: center;
  margin-bottom: 60px;
}

.pc-continer .soldier .soldiers .enlargeBox {
  width: 100%;
  height: 236px;
  display: flex;
  justify-content: space-between;
}

.pc-continer .soldier .soldiers .enlargeBox li {
  width: 226px;
  height: 236px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.pc-continer .soldier .soldiers .enlargeBox li h4 {
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  text-align: center;
  margin-bottom: 13px;
}

.pc-continer .soldier .soldiers .enlargeBox li a {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F24FF;
  line-height: 20px;
  display: block;
  text-align: center;
}

.pc-continer .soldier .soldiers .enlargeBox .scenario-pic:hover {
  transform: scale(1.2);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.pc-continer .soldier .soldiers .enlargeBox li:nth-child(1) .scenario-pic {
  width: 226px;
  height: 139px;
  background: url("../../assets/image/home/5L-img1.png") no-repeat;
  background-size: cover;
  transition: transform .3s 0s ease-in-out;
}

.pc-continer .soldier .soldiers .enlargeBox li:nth-child(2) .scenario-pic {
  width: 226px;
  height: 139px;
  background: url("../../assets/image/home/5L-img2.png") no-repeat;
  background-size: cover;
  transition: transform .3s 0s ease-in-out;
}

.pc-continer .soldier .soldiers .enlargeBox li:nth-child(3) .scenario-pic {
  width: 226px;
  height: 139px;
  background: url("../../assets/image/home/5L-img3.png") no-repeat;
  background-size: cover;
  transition: transform .3s 0s ease-in-out;
}

.pc-continer .soldier .soldiers .enlargeBox li:nth-child(4) .scenario-pic {
  width: 226px;
  height: 139px;
  background: url("../../assets/image/home/5L-img4.png") no-repeat;
  background-size: cover;
  transition: transform .3s 0s ease-in-out;
}

.pc-continer .soldier .soldiers .enlargeBox li:nth-child(5) .scenario-pic {
  width: 226px;
  height: 139px;
  background: url("../../assets/image/home/5L-img5.png") no-repeat;
  background-size: cover;
  transition: transform .3s 0s ease-in-out;
}
