.mobileheader {
  width: 100%;
  background-color: pink;
  display: flex;
  justify-content: space-between;
  padding: .3rem;
}

.mobileheader li {
  margin-top: .25rem;
}

.mobileheader li .logo {
  width: 2.1rem;
  height: .5rem;
  object-fit: cover;
}

.mobileheader li .ico {
  width: .48rem;
  height: .48rem;
  object-fit: cover;
}
